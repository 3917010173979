<template>
  <WebAppLayout>
    <slot />
  </WebAppLayout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import WebAppLayout from "../components/webAppLayout.vue";
export default defineComponent({
  name: "AuthenticatedLayout",
  components: {
    WebAppLayout,
  },
});
</script>
